import apiPath from "@/plugins/api";
import { axios as $axios, instance as axios } from "../../services/axios";
export const state = () => ({
  state: {
    movementType: null,
    stockList: null,
  },
});

export const mutations = {
  setMovementType: async function (state, val) {
    state.movementType = val;
  },
  setStockList: async function (state, val) {
    state.stockList = val;
  },
};

export const actions = {
  getMovementType: async ({ commit, state }, payload) => {
    if (state.movementType) return state.movementType;
    return await axios(`/Inventory/GetInventoryActivity`).then(
      async (data) => {
        const flatData = data.data.detail.map((el) => {
          return { text: el.name, value: Number(el.id) };
        });
        commit("setMovementType", flatData);
        return flatData;
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
        }
      }
    );
  },
  getStockList: async ({ commit, state }, payload) => {
    if (state.stockList) return state.stockList;
    return await axios(`/Inventory/GetMasterStockType`).then(
      async (data) => {
        const flatData = data.data.detail.map((el) => {
          return { text: el.name, value: Number(el.id) };
        });
        commit("setStockList", flatData);
        return flatData;
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
        }
      }
    );
  },
};

export default {
  state,
  actions,
  mutations,
};
