import Vue from "vue";

Vue.prototype.$helper = {
  status: (params) => {
    if (params) return "Active";
    else return "Inactive";
  },
  customPromiseAll: (fns) =>
    new Promise((resolve, reject) => {
      let results = [],
        completed = 0;
      if (!fns.length) return resolve([]);
      fns.reduce(
        (p, fn, i) =>
          p
            .then(() =>
              fn().then((r) => {
                results[i] = r;
                if (++completed === fns.length) resolve(results);
              })
            )
            .catch(reject),
        Promise.resolve()
      );
    }),

  // ตัวอย่างการใช้งาน
  // เผื่อได้ใช้
  // const promiseFunctions = [
  //     () => axios.get('https://api.example.com/data1'),
  //     () => axios.get('https://api.example.com/data2'),
  //     () => axios.get('https://api.example.com/data3')
  // ];
  // customPromiseAll(promiseFunctions).then(console.log).catch(console.error);
};
