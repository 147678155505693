const SerialCustomObject = {
  path: "",
  name: "",
  redirect: "serial-custom-object",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "serial-custom-object",
      name: "Serial Custom Object",
      redirect: "serial-custom-object",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/ProductSetting/ProductSerialCustomObject"
            ),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: ":id",
          name: "Serial Custom Object Detail",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/ProductSetting/ProductSerialCustomObject/detail"
            ),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
      ],
    },
  ],
};
const GroupBarcode = {
  path: "",
  name: "",
  redirect: "group-barcode",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "group-barcode",
      name: "Group Barcode",
      redirect: "group-barcode",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/ProductSetting/GroupBarcode"
            ),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: ":id",
          name: "Group Barcode Detail",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/ProductSetting/GroupBarcode/detail"
            ),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
      ],
    },
  ],
};
const ProductCustomFields = {
  path: "",
  name: "",
  redirect: "product-setting",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "product-setting",
      name: "Product Setting",
      redirect: "product-setting",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/ProductSetting"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "serial-custom-fields",
          name: "Product Serial Custom Fields",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/ProductSetting/ProductSerialCustomField"
            ),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "custom-field",
          name: "Product Custom Field",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/ProductSetting/ProductCustomField"
            ),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        SerialCustomObject,
        GroupBarcode,
      ],
    },
  ],
};

export default ProductCustomFields;
