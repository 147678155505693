const ProductRepair = {
  path: "",
  name: "",
  redirect: "product-repair",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "product-repair",
      name: "Product Repair",
      redirect: "product-repair",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Repair/ProductRepair"),
          meta: {
            requiresAuth: true,
            activeUrl: "/product-repair",
            parent: "/product-repair",

            lv: 2,
          },
        },
      ],
    },
  ],
};

const RepairCuase = {
  path: "",
  name: "",
  redirect: "repair-cause",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "repair-cause",
      name: "Rerpair Cause",
      redirect: "repair-cause",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Repair/RepairCauseIndex"),
          meta: {
            requiresAuth: true,
            activeUrl: "/repair-cause",
            parent: "/repair-cause",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Rerpair Cause Detail",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/Repair/RepairCauseDetail"
            ),
          meta: {
            requiresAuth: true,
            activeUrl: "detail/:id",
            parent: "/repair-cause",

            lv: 3,
          },
        },
      ],
    },
  ],
};

const RepairCustomField = {
  path: "",
  name: "",
  redirect: "custom-field",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "custom-field",
      name: "Rerpair Custom Field",
      redirect: "custom-field",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Repair/CustomField"),
          meta: {
            requiresAuth: true,
            activeUrl: "custom-field",
            parent: "/custom-field",

            lv: 2,
          },
        },
      ],
    },
  ],
};
const RepairSetting = {
  path: "",
  name: "",
  redirect: "gold-product",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "repair",
      name: "Repair",
      redirect: "repair",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Repair/index"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "detail",
          name: "Repair Detail",
          component: () =>
            import("@/views/pages/setting/settingPage/Repair/detail"),
          meta: {
            requiresAuth: true,
            activeUrl: "detail",
            isChildren: true,
            lv: 3,
          },
        },
        ProductRepair,
        RepairCuase,
        RepairCustomField
      ],
    },
  ],
};
export default RepairSetting;
