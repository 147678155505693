const PaymentType = {
    path: "",
    name: "",
    redirect: "payment-type",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      requiresAuth: true,
      activeUrl: "/setting",
      isChildren: true,
      lv: 2,
    },
    children: [
      {
        path: "payment-type",
        name: "Payment Type",
        redirect: "payment-type",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          requiresAuth: true,

          activeUrl: "/setting",
          isChildren: true,
          lv: 2,
        },
        children: [
          {
            path: "",
            name: "",
            component: () =>
              import("@/views/pages/setting/settingPage/Payment/PaymentType"),
            meta: {
              requiresAuth: true,

              activeUrl: "/setting",
              isChildren: true,
              lv: 3,
            },
          },
          {
            path: ":id",
            name: "Payment Type Detail",
            redirect: "/setting/payment-type",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            meta: {
              requiresAuth: true,

              activeUrl: "/setting",
              isChildren: true,
              lv: 2,
            },
            children: [
              {
                path: "",
                name: "",
                component: () =>
                  import(
                    "@/views/pages/setting/settingPage/Payment/PaymentType/detail"
                  ),
                meta: {
                  requiresAuth: true,

                  activeUrl: "/setting",
                  isChildren: true,
                  lv: 2,
                },
              },
              // {
            ],
          },
        ],
      },
    ],
  }

  const PaymentSetting = {
    path: "",
    name: "",
    redirect: "payment",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      requiresAuth: true,
      activeUrl: "/setting",
      isChildren: true,
      lv: 2,
    },
    children: [
      {
        path: "payment",
        name: "Payment",
        redirect: "payment",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          requiresAuth: true,

          activeUrl: "/setting",
          isChildren: true,
          lv: 2,
        },
        children: [
          {
            path: "",
            name: "",
            component: () =>
              import("@/views/pages/setting/settingPage/Payment"),
            meta: {
              requiresAuth: true,

              activeUrl: "/setting",
              isChildren: true,
              lv: 3,
            },
          },
          PaymentType
        ],
      },
    ],
  }
  export default PaymentSetting;