<template>
  <div class="mb-2">
    <label v-if="textFloat" class="main-label">
      {{ textFloat }}
      <span v-if="isRequired" class="text-error">*</span>
    </label>

    <v-select
      ref="select"
      v-model="selected"
      :options="paginated"
      :label="textField"
      :filterable="false"
      @open="onOpen"
      @close="onClose"
      @input="push"
      :multiple="multiple"
      :placeholder="placeholder"
      :closeOnSelect="multiple ? false : true"
      :clearSearchOnSelect="true"
      :clearSearchOnBlur="clearSearchOnBlur"
      @search="(query) => (search = query)"
      :taggable="multiple"
      :clearable="clearable"
      :class="[
        multiple ? 'multiple-select' : 'single-select',
        { error: isValidate },
      ]"
      :disabled="disabled"
    >
      <template #search="{ attributes, events }">
        <input
          v-if="searchPlaceHolder"
          class="vs__search w-100"
          :placeholder="placeholder"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false">{{
        textValidate
      }}</span>
      <!-- <span class="text-error" v-else-if="v.minValue == false">{{options[0][textField || 'text']}}</span> -->
      <span class="text-error" v-else-if="v.minValueMain == false"
        >Please select value</span
      >
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
export default {
  name: "SelectWithInfinite",

  props: {
    options: {
      required: true,
      default: [],
    },
    value: {
      required: false,
      default: [],
    },
    textField: {
      required: false,
      default: "name",
    },
    valueField: {
      required: false,
      default: "id",
    },
    limit: {
      required: false,
      default: 100,
    },
    textFloat: {
      required: false,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
    },
    multiple: {
      required: false,
      default: true,
    },
    rawValue: {
      required: false,
      type: Boolean,
      default: false,
    },
    clearable: {
      required: false,
      default: true,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
    },
    textValidate: {
      required: false,
      default: "Please select value.",
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      observer: null,
      search: "",
      selected: [],
      limitValue: this.limit,
      searchPlaceHolder: false,
    };
  },

  computed: {
    filtered() {
      if (this.options.length > 0)
        return this.options.filter((options) =>
          options[this.textField]
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      else return [];
    },
    paginated() {
      return this.filtered.slice(0, this.limitValue);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  watch: {
    options(val) {
      if (val)
        if (this.multiple) {
          this.selected = this.options.filter(
            (el) => this.value == el[this.valueField]
          );
        } else {
          this.selected = this.options.filter(
            (el) => this.value == el[this.valueField]
          );
        }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);

    if (this.options.length > 0)
      if (this.multiple) {
        this.selected = this.options.filter(
          (el) => this.value == el[this.valueField]
        );
      } else {
        this.selected = this.options.filter(
          (el) => this.value == el[this.valueField]
        );
      }
  },
  methods: {
    async onOpen() {
      this.searchPlaceHolder = true;
      this.search = "";
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
      this.search = "";

      this.searchPlaceHolder = false;
    },

    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitValue += 10;
        // this.getProductList();
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    push(e) {
      let value = null;
      if (this.multiple) {
        value = e.map((el) => el[this.valueField]);
      } else if (this.rawValue) value = e[this.valueField];
      else {
        value = [e[this.valueField]];
      }

      // this.selected = ;
      this.$emit("input", value);
      // document.getElementsByClassName("vs__search")[0].blur();
    },
    clearSearchOnBlur(a, b) {
      return true;
    },
  },
};
</script>

<style>
.vs__search {
  color: rgba(22, 39, 74, 0.4);
  display: inline-block;
  margin-bottom: 3px;
  padding-top: 2px;
  font-size: 14px;
}
.vs__search:focus {
  border-color: transparent !important;
}
.vs__dropdown-option--selected {
  background-color: var(--primary-color) !important;
  color: var(--font-primary-color) !important;
}

.multiple-select > .vs__selected {
  background-color: var(--primary-color) !important;
  color: var(--font-primary-color) !important;
}
.vs--single.vs--open .vs__selected {
  opacity: 1 !important;
  position: relative !important;
}
.vs--searchable .vs__dropdown-toggle {
  cursor: pointer !important;
}
ul li {
  color: black !important;
}
.error .vs__dropdown-toggle {
  border-color: red;
}
</style>
