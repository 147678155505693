const GoldPrice = {
    path: "",
    name: "",
    redirect: "gold-price",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      requiresAuth: true,
      activeUrl: "/setting",
      isChildren: true,
      lv: 2,
    },
    children: [
      {
        path: "gold-price",
        name: "Gold Price",
        redirect: "gold-price",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          requiresAuth: true,
  
          activeUrl: "/setting",
          isChildren: true,
          lv: 2,
        },
        children: [
          {
            path: "",
            name: "",
            component: () => import("@/views/pages/setting/settingPage/GoldProduct/GoldPrice"),
            meta: {
              requiresAuth: true,
              activeUrl: "/gold-price",
              parent: "/gold-price",
  
              lv: 2,
            },
          },
        ],
      },
    ],
  };
  
  const zGoldVariable = {
    path: "",
    name: "",
    redirect: "zgold-variabl",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      requiresAuth: true,
      activeUrl: "/setting",
      isChildren: true,
      lv: 2,
    },
    children: [
      {
        path: "zgold-variable",
        name: "ZGOLD VARIABLE",
        redirect: "zgold-variableck",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          requiresAuth: true,
  
          activeUrl: "/setting",
          isChildren: true,
          lv: 2,
        },
        children: [
          {
            path: "",
            name: "",
            component: () => import("@/views/pages/setting/settingPage/GoldProduct/GoldVariable"),
            meta: {
              requiresAuth: true,
              activeUrl: "/zgold-variable",
              parent: "/zgold-variable",
  
              lv: 2,
            },
          },
        ],
      },
    ],
  };
  const GoldProduct = {
    path: "",
    name: "",
    redirect: "gold-product",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      requiresAuth: true,
      activeUrl: "/setting",
      isChildren: true,
      lv: 2,
    },
    children: [
      {
        path: "gold-product",
        name: "Gold Product",
        redirect: "gold-product",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          requiresAuth: true,
          activeUrl: "/setting",
          isChildren: true,
          lv: 2,
        },
        children: [
          {
            path: "",
            name: "",
            component: () =>
              import("@/views/pages/setting/settingPage/GoldProduct/index"),
            meta: {
              requiresAuth: true,
              activeUrl: "/setting",
              isChildren: true,
              lv: 3,
            },
          },
          GoldPrice,
          zGoldVariable
        ],
      },
    ],
  };
  export default GoldProduct;
  