const InventoryTransfer = {
  path: "",
  name: "",
  redirect: "/inventory-transfer",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/inventory-transfer",
      redirect: "/inventory-transfer",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Inventory Transfer",
      meta: {
        requiresAuth: true,
        activeUrl: "/inventory-transfer",
        parent: "/inventory-transfer",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/inventoryTransfer/index"),
          meta: {
            requiresAuth: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Inventory Transfer Detail",
          component: () => import("@/views/pages/inventoryTransfer/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "detail/:id",
            parent: "detail/:id",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const PurchaseOrder = {
  path: "",
  name: "",
  redirect: "/purchase-order",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/purchase-order",
      redirect: "/purchase-order",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Purchase Order",
      meta: {
        requiresAuth: true,
        activeUrl: "/purchase-order",
        parent: "/purchase-order",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/purchaseOrder"),
          meta: {
            requiresAuth: true,
            activeUrl: "/purchase-order",
            parent: "/purchase-order",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Purchase Order Detail",
          component: () => import("@/views/pages/purchaseOrder/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/purchase-order",
            parent: "/purchase-order",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const RepairProduct = {
  path: "",
  name: "",
  redirect: "/repair",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/repair",
      redirect: "/repair",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "REP - Repair Product",
      meta: {
        requiresAuth: true,
        activeUrl: "/repair",
        parent: "/repair",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/purchaseOrder"),
          meta: {
            requiresAuth: true,
            activeUrl: "/repair",
            parent: "/repair",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Repair Product Detail",
          component: () => import("@/views/pages/purchaseOrder/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/repair",
            parent: "/repair",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const RequestPurchase = {
  path: "",
  name: "",
  redirect: "/request-purchase",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/request-purchase",
      name: "RQP",
      redirect: "/request-purchase",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/request-purchase",
        isChildren: true,
        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/requestPurchase/index"),
          meta: {
            requiresAuth: true,
            activeUrl: "/request-purchase",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "log/:log_id",
          name: "RQP Log",
          component: () =>
            import("@/views/pages/setting/settingPage/requestPurchase/logs"),
          meta: {
            requiresAuth: true,
            activeUrl: "/request-purchase",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "detail/:id",
          name: "RQP Detail",

          component: () =>
            import("@/views/pages/setting/settingPage/requestPurchase/detail"),
          meta: {
            requiresAuth: true,

            activeUrl: "/request-purchase",
            isChildren: true,
            lv: 3,
          },
        },
      ],
    },
  ],
};
export { PurchaseOrder, RequestPurchase, InventoryTransfer, RepairProduct };
