<template>
  <div>
    <b-row class="align-items-center">
      <b-col cols="12" class="main-label"
        >{{ textFloat }}
        <span class="text-error" v-if="isRequired">*</span></b-col
      >

      <b-col cols="12" sm="4" v-if="countryShow">
        <InputSelect
          :options="countryList"
          valueField="value"
          textField="name"
          :value="country"
          @onDataChange="(val) => onChange(val)"
          :disabled="disabled"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled>
              -- Please Select Phone Code --
            </b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
      <b-col cols="12" :sm="countryShow ? 8 : 12" class="d-inline-flex w-100">
        <InputText
          textFloat=""
          :type="'text'"
          v-model="telephone"
          name="telephone"
          :placeholder="placeholder"
          :isValidate="isValidate"
          :v="v"
          class="flex-shrink float-validate"
          @input="(val) => joinTelephone(val)"
          @onKeypress="isNumberKey"
          :oninput="
            country == 1
              ? 'javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);'
              : ''
          "
          :disabled="disabled"
        />
        <InputText
          textFloat=""
          type="number"
          v-model="ext"
          name="Ext. telephone"
          class="pl-2 flex-shrink-2"
          @onKeypress="isNumberKey"
          :disabled="disabled || telephone == 0"
          placeholder="Ext."
          @input="(val) => joinTelephone(val)"
        />
        <b-button
          style="margin: 0 0 15px 5px"
          variant="custom-link"
          @click="$emit('delete')"
          v-if="!countryShow && !removeIcon"
          ><font-awesome-icon icon="trash-alt"></font-awesome-icon
        ></b-button>
      </b-col>
      <!-- <b-col> </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: { required: false, default: "Telephone", Type: String },
    country: {
      required: false,
    },
    value: {
      required: true,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: "",
    },
    textValidate: {
      required: false,
      default: "Please input value.",
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object || null || undefined,
    },
    countryShow: {
      required: false,
      default: true,
      type: Boolean,
    },
    removeIcon: {
      required: false,
      default: false,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      countryList: [],
      ext: "",
      telephone: "",
    };
  },
  watch: {
    value: {
      handler(newVal) {
        if (this.countryShow) {
          this.ext = newVal ? newVal.split("-")[1] : "";
          this.telephone = newVal ? newVal.split("-")[0] : "";
        }
      },
      immediate: true,
    },
  },

  created() {
    if (this.countryShow) this.getCountry();
  },
  mounted() {
    if (!this.countryShow) this.formatValue();
  },
  methods: {
    formatValue() {
     
      if (this.value) {
        let value = this.value.split("-");
        if (value.length > 2) {
          this.ext = value.length > 1 ? value[value.length - 1] : "";
          value.splice(value.length - 1, 1);
       
          this.telephone = value.length > 0 ? value.join("-") : "";
        } else {
     
          this.ext = value.length > 1 ? value[1] : "";
          this.telephone = value.length > 0 ? value[0] : "";
        }
        this.$nextTick();
      }
    },
    async getCountry() {
      const res = await this.$store.dispatch("getCountryCode");

      this.countryList = res.data.detail.map((el) => {
        return { name: `${el.name} (${el.dial_code})`, value: el.id };
      });
    },
    isNumberKey(e) {
      var charCode = e.which ? e.which : e.keyCode;

      if (
        !this.countryShow &&
        charCode > 31 &&
        (charCode < 43 || charCode > 57 || charCode == 46 || charCode == 47)
      ) {
        e.preventDefault();
        return;
      } else if (
        this.countryShow &&
        charCode > 31 &&
        (charCode < 48 || charCode > 57)
      ) {
        e.preventDefault();
        return;
      }
      return;
    },
    joinTelephone(val) {
      if (this.telephone.length == 0) this.ext = "";
      let value = `${this.telephone}${this.ext ? "-" + this.ext : ""}`;

      this.$emit("input", value);
    },
    onChange(val) {
      this.telephone = "";
      this.ext = "";
      let value = `${this.telephone}${this.ext ? "-" + this.ext : ""}`;
      this.$emit("input", value);
      this.$emit("set", val);
    },
  },
};
</script>

<style>
.flex-shrink-2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}
.flex-shrink {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
}
</style>
